import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ElasticSearchModel from 'src/model/elasticsearch/ElasticSearchModel'
import VehicleCard from 'src/components/Card/VehicleCard.vue'

export default {
  name: 'VeiculosDestaque',
  components: { VueSlickCarousel, VehicleCard },
  props: {
    marcaId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      featured: [],
      carrosselCards: {
        lazyLoad: 'ondemand',
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: false,
        responsive: [
          {
            breakpoint: 2400,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              initialSlide: 0
            }
          },
          {
            breakpoint: 1450,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              initialSlide: 0
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              initialSlide: 0
            }
          },
          {
            breakpoint: 1020,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 0
            }
          },
          {
            breakpoint: 915,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          },
          {
            breakpoint: 599,
            settings: {
              slidesToShow: 1.1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  watch: {
    'marcaId' (newValue, oldValue) {
      this.getFeatured()
    }
  },
  mounted () {
    this.getFeatured()
  },
  computed: {
    featuredList () {
      return this.featured
    }
  },
  methods: {
    getFeatured () {
      this.featured = []
      let modelElastic = new ElasticSearchModel()
      let filterOptions = [
        { tipo: 'multiplo', termo: 'destaque', label: 'Destaque', valor: true },
        { tipo: 'multiplo', termo: 'situacao_id', label: 'Situação', valor: 1 },
        { tipo: 'multiplo', termo: 'situacao_id', label: 'Situação', valor: 6 }
      ]
      if (this.marcaId) {
        filterOptions.push({ tipo: 'multiplo', termo: 'marca_objeto.marca_id', label: 'Marca', valor: this.marcaId })
      }
      modelElastic.buscar(filterOptions, null, 1, 8).then((retorno) => {
        this.featured = retorno.hits.hits.map((item) => item._source)
      }).catch((e) => console.error('e', e))
    }
  }
}
